<template>
  <v-app>
    <v-card class="mx-auto" style="max-width: 500px;">
      <v-system-bar color="deep-purple darken-4" dark>
        <v-spacer></v-spacer>
        <v-icon small>
          mdi-square
        </v-icon>
        <v-icon class="ml-1" small>
          mdi-circle
        </v-icon>
        <v-icon class="ml-1" small>
          mdi-triangle
        </v-icon>
      </v-system-bar>
      <v-toolbar color="deep-purple accent-4" cards dark flat>
        <v-btn to="/home" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-card-title class="text-h6 font-weight-regular">
          サインアップ
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="form" v-model="form" class="pa-4 pt-6">
        <v-textarea v-model="userId" :rules="[rules.userId]" auto-grow filled color="deep-purple" label="ユーザID" rows="1"></v-textarea>
        <v-text-field v-model="password" :rules="[rules.password, rules.length(8)]" filled color="deep-purple" counter="8"
          label="パスワード" style="min-height: 96px" type="password"></v-text-field>
        <v-text-field v-model="passwordConfirm"  auto-grow filled color="deep-purple" label="パスワード確認" rows="1" type="password"></v-text-field>
        <v-text-field v-model="email" :rules="[rules.email]" filled color="deep-purple" label="メールアドレス"
          type="email"></v-text-field>
        <v-text-field v-model="telNo" filled color="deep-purple" label="電話番号"></v-text-field>
        <v-textarea v-model="userLastName" :rules="[rules.required]" auto-grow filled color="deep-purple" label="性" rows="1"></v-textarea>
        <v-textarea v-model="userFirstName" :rules="[rules.required]" auto-grow filled color="deep-purple" label="名" rows="1"></v-textarea>
        <v-textarea v-model="zipCode" :rules="[rules.zipCode]" auto-grow filled color="deep-purple" label="郵便番号" rows="1"></v-textarea>
        <v-textarea v-model="address" :rules="[rules.required]" auto-grow filled color="deep-purple" label="住所" rows="1"></v-textarea>
        <v-checkbox v-model="agreement" :rules="[rules.required]" color="deep-purple">
          <template v-slot:label>
            <a href="#" @click.stop.prevent="dialog = true">会員規約</a>
            &nbsp;と&nbsp;
            <a href="#" @click.stop.prevent="dialog = true">プライバシーポリシー</a>に同意する
          </template>
        </v-checkbox>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="$refs.form.reset()">
          クリア
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-on:click="signup" :disabled="!form" :loading="isLoading" class="white--text" color="deep-purple accent-4" depressed >
          登録
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="dialog" absolute max-width="400" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-3">
            プライバシーポリシーと会員規約
          </v-card-title>
          <v-card-text>
            <PolicyComponent />
            <TermsComponent />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="agreement = false, dialog = false">
              閉じる
            </v-btn>
            
            <v-spacer></v-spacer>
            <v-btn class="white--text" color="deep-purple accent-4" @click="agreement = true, dialog = false">
              同意する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-app>
 
</template>

<script>
import PolicyComponent from '@/components/policy';
import TermsComponent from '@/components/terms';
import axios from 'axios';
export default {
  name: 'SignupComponet',
  data: () => ({
    agreement: false,
    userId: undefined,
    userFirstName: undefined,
    userLastName: undefined,
    address: undefined,
    zipCode: undefined,
    dialog: false,
    email: undefined,
    form: false,
    isLoading: false,
    password: undefined,
    passwordConfirm: undefined,
    telNo: undefined,
    rules: {
      email: v => !!(v || '').match(/@/) || '有効なメールアドレスを入力してください',
      length: len => v => (v || '').length >= len || `文字数 ${len}`,
      password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/) ||
        'パスワードは大文字、数字を使用してください',
      required: v => !!v || '入力してください',
      userId: v => !!(v || '').match(/^(?=.*[a-z]).+$/) ||
        '半角英数字で入力してください',
      zipCode: v => !!(v || '').match(/^(?=.*\d).+$/) ||
      '数字で入力してください',
    },
  }),
  methods: {
    signup () {
      if ((this.password === this.passwordConfirm)) {
        this.$cognito.signUp(this.userId, this.email, this.password)
          .then(result => {
            // 登録に成功したら、確認コードの入力画面を表示
            this.$router.replace('/confirm')
            this.postUserData()
            console.log(result)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    async postUserData() {
      const data = {
        userId: this.userId,
        email: this.email,
        telNo: this.telNo,
        userLastName: this.userLastName,
        userFirstName: this.userFirstName,
        userName: this.userLastName + ' ' + this.userFirstName,
        zipCode: this.zipCode,
        address: this.address,
      };

      try {
        const response = await axios.post('https://1xel0yl9ye.execute-api.ap-northeast-1.amazonaws.com/v1/postUser', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // POSTリクエストが成功した場合の処理
        console.log(response.data);
      } catch (error) {
        // POSTリクエストがエラーの場合の処理
        console.error(error);
      }
    }
  },
  components: {
        PolicyComponent,
        TermsComponent
    },
}
</script>