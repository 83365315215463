<template>
    <div class="membership-form">
      <h2 class="form-title">入会フォーム</h2>
  
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">お名前</label>
          <input type="text" id="name" v-model="name" required>
        </div>
  
        <div class="form-group">
          <label for="email">メールアドレス</label>
          <input type="email" id="email" v-model="email" required>
        </div>
  
        <div class="form-group">
          <label for="membership-type">会員種別</label>
          <select id="membership-type" v-model="membershipType" required>
            <option value="">選択してください</option>
            <option value="法人会員">法人会員</option>
            <option value="個人会員">個人会員</option>
            <option value="互助会会員">互助会会員</option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="message">メッセージ</label>
          <textarea id="message" v-model="message"></textarea>
        </div>
  
        <button type="submit" class="submit-button" @click="redirectToPayment">送信</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'membershipComponent',
    data() {
      return {
        name: '',
        email: '',
        membershipType: '',
        message: ''
      };
    },
    methods: {
      submitForm() {
        // 入力内容の送信処理を実行する
        // バリデーションやサーバーへのリクエストなどを追加する
        console.log('フォームが送信されました');
        console.log('名前:', this.name);
        console.log('メールアドレス:', this.email);
        console.log('会員種別:', this.membershipType);
        console.log('メッセージ:', this.message);
  
        // フォーム送信後の処理を追加する
      },
      redirectToPayment() {
      // 支払い完了後に遷移するページのURL
      const paymentURL = '/payment';

      // ルーターを使用して遷移する
      this.$router.push(paymentURL);
    }
    }
  };
  </script>
  
  <style scoped>
  .membership-form {
    max-width: 400px;
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea,
  select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    background-color: #333;
    color: #fff;
  }
  .submit-button:hover {
    background-color: #555;
  }

  .submit-button:focus {
    outline: none;
  }
</style>

