<template>
    <div class="payment-success">
      <h1 class="title">決済が完了しました！</h1>
      <p class="message">お支払いが正常に処理されました。</p>
      <div class="button-container">
        <router-link class="back-button" to="/">ホームに戻る</router-link>
      </div>
    </div>
  </template>
  
  <style>
  .payment-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .message {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .button-container {
    display: flex;
  }
  
  .back-button {
    padding: 10px 20px;
    background-color: #4285F4;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #1A73E8;
  }
  </style>
  