import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from '../components/home.vue';
import cognito from '@/service/cognito';
import LoginComponent from '@/pages/login';
import SignupComponent from '@/pages/signup';
import ConfirmComponent from '@/pages/confirm';
import MembershipListComponent from '@/pages/membershipList';
import MembershipComponent from '@/pages/membership';
import PaymentComponent from '@/pages/payment';
import PaymentResultComponent from '@/pages/paymentResult';
import ContactComponent from '@/pages/contact';
import PolicyComponent from '@/pages/policy';
import MypageComponent from '@/pages/mypage';
import TermsComponent from '@/pages/terms';
import SocietyComponent from '@/pages/society';
import PartnerComponent from '@/pages/partner';
import ServiceComponent from '@/pages/service';
import AboutusComponent from '@/pages/aboutus';
import CompleteComponent from '@/pages/complete';
import LogoutComponent from '@/components/certification/logout';
import NewsComponent from '@/pages/news';



const requireAuth = (to, from, next) => {
  cognito.isAuthenticated()
    .then(session => {
      next()
      console.log(session);
    })
    .catch(session => {
      console.log(session);
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    })
}

const logout = (to, from, next) => {
  cognito.logout()
  next('/login')
}

const routes = [
  { path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'HomeComponent',
    component: HomeComponent
  },
  {
    path: '/login',
    name: 'LoginComponent',
    component: LoginComponent
  },
  {
    path: '/signup',
    name: 'SignupComponent',
    component: SignupComponent
  },
  {
    path: '/confirm',
    name: 'ConfirmComponent',
    component: ConfirmComponent
  },
  { path: '/logout',
    component: LogoutComponent,
    beforeEnter: logout
  },
  {
    path: '/membership-list',
    component: MembershipListComponent,
  },
  {
    path: '/membership',
    component: MembershipComponent,
    beforeEnter: requireAuth
  },
  {
    path: '/payment',
    component: PaymentComponent,
    beforeEnter: requireAuth
  },
  {
    path: '/payment-result',
    component: PaymentResultComponent,
  },
  {
    path: '/contact',
    component: ContactComponent,
  },
  {
    path: '/privacy-policy',
    component: PolicyComponent,
  },
  {
    path: '/mypage',
    component: MypageComponent,
  },
  {
    path: '/terms',
    component: TermsComponent,
  },
  {
    path: '/partner',
    component: PartnerComponent,
  },
  {
    path: '/service',
    component: ServiceComponent,
  },
  {
    path: '/society',
    component: SocietyComponent,
  },
  {
    path: '/aboutus',
    component: AboutusComponent,
  },
  {
    path: '/complete',
    component: CompleteComponent,
  },
  {
    path: '/news/:id', 
    name: 'News',
    component: NewsComponent,
    props: true 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;