<template>
    <div>
        <PartnerComponent />
    </div>
</template>
  
<script>
import PartnerComponent from '@/components/partner';

export default {
    name: 'PartnerPage',
    components: {
        PartnerComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>