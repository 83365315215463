import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import cognito from './service/cognito'
import vuetify from "./plugins/vuetify";

const app = createApp(App)
app.config.globalProperties.$cognito = cognito

app.use(router)

app.use(vuetify).mount("#app");