<template>
  <div id="app">
    <div id="header">
      <HeaderComponent />
    </div>
    <div class="content-container">
      <router-view></router-view>
    </div>
    <div id="footer">
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/globals/header.vue';
import FooterComponent from '@/globals/footer.vue';

export default {
    name: 'TopPage',
    components: {
      HeaderComponent,
      FooterComponent
    },
    // ページコンポーネントのオプション
}
</script>
<style>
.content-container {
  margin-top: 70px;
  z-index: 1;
}
#header {
  z-index: 999;
  position: absolute;
}
#footer {
  margin-top: 3px;
  /* z-index: 999;
  position: absolute; */
}
</style>