<template>
  <div class="confirm">
    <h2 class="confirm__title">確認コード入力</h2>
    <h3>入力されたメールアドレス宛に届いております確認コードをご確認のうえ、以下に入力してください</h3>
    <form @submit.prevent="confirm" class="confirm__form">
      <div class="confirm__input-group">
        <label for="username" class="confirm__label">ユーザID:</label>
        <input type="text" id="username" class="confirm__input" placeholder="ユーザID" v-model="username" required>
      </div>
      <div class="confirm__input-group">
        <label for="confirmationCode" class="confirm__label">確認コード:</label>
        <input type="text" id="confirmationCode" class="confirm__input" placeholder="確認コード" v-model="confirmationCode" required>
      </div>
      <button class="confirm__button">確認</button>
    </form>
  </div>
</template>
  
  <script>
  export default {
    name: 'ConfirmComponent',
    data () {
      return {
        username: '',
        confirmationCode: ''
      }
    },
    methods: {
      confirm () {
        this.$cognito.confirmation(this.username, this.confirmationCode)
          .then(result => {
            this.$router.replace('/complete')
            console.log(result
            );
          })
          .then(err => {
            this.error = err
          })
      }
    }
  }
  </script>
  <style>
  .confirm {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
  }
  
  .confirm__title {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .confirm__form {
    margin-top: 20px;
  }
  
  .confirm__input-group {
    margin-bottom: 10px;
  }
  
  .confirm__label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .confirm__input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .confirm__button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm__button:hover {
    background-color: #0056b3;
  }
  </style>