<template>
    <div>
        <ContactComponent />
    </div>
</template>
  
<script>
import ContactComponent from '@/components/contact.vue';

export default {
    name: 'ContactPage',
    components: {
        ContactComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>