<template>
    <div>
        <NewsComponent />
    </div>
</template>
  
<script>
import NewsComponent from '@/components/news.vue';

export default {
    name: 'NewsPage',
    components: {
        NewsComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>