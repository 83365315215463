<template>
  <div class="service-page">
    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;Service&nbsp;&nbsp;&nbsp;/</h1>
    </div>
    <div class="gazou">
      <v-img v-bind:src="require('../assets/daichi3.jpg')"></v-img>
    </div>

    <h2 class="sub-title">アート制作発表</h2>
    <hr class="under-bar">
    <p class="descriptioin">
      新時代を代表する各ジャンルのアーティストが続々と新作を発表。
      また、ご依頼人様とアーティストの打ち合わせを経て、
      オーダーメイドで世界に一つだけの作品を制作することも出来ます。
    </p>

    <h2 class="sub-title">コレクターズクラブ</h2>
    <hr class="under-bar">
    <p class="descriptioin">
      saf発表アート作品を所有する方限定のコミュニティを設けております。
      アートマーケットへの進出サポートや、国内外アーティストとの直接的交流、
      多業種に渡るコレクター様同士の交流をご提供いたします。
    </p>

    <h2 class="sub-title">企業コラボレーション</h2>
    <hr class="under-bar">
    <p class="descriptioin">
      企業スポンサー様には、当財団の様々なシーンにおいてCSRと広報の機会を設けさせていただきます。
      また、当財団推薦アーティスト達によるコラボレーション企画をご提供致します。
    </p>

    <h2 class="sub-title">会員コミュニティ</h2>
    <hr class="under-bar">
    <p class="descriptioin">
      個人会員様には、各種イベントへのご招待と、saf推薦アーティストからのプレゼントをご提供いたします。
    </p>

  </div>
</template>
  
<script>
export default {
  name: 'ServiceComponent',
};
</script>
  
<style scoped>
.service-page {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.sub-title {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 10px;
}

.description {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.under-bar {
  border: none; 
  background-color: #000; 
  height: 1px; 
  width: 70px; 
  margin-bottom: 30px;
}

</style>