<template>
    <div>
        <ConfirmComponent />
    </div>
</template>
  
<script>
import ConfirmComponent from '@/components/certification/confirm';

export default {
    name: 'ConfirmPage',
    components: {
        ConfirmComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>