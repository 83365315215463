<template>
    <div class="payment-page">
      <h1>決済ページ</h1>
      <div class="payment-form">
        <div class="form-group">
          <label for="card-number">カード番号</label>
          <input id="card-number" type="text" v-model="cardNumber" placeholder="1234 5678 9012 3456" />
        </div>
        <div class="form-group">
          <label for="expiry-date">有効期限</label>
          <input id="expiry-date" type="text" v-model="expiryDate" placeholder="MM/YY" />
        </div>
        <div class="form-group">
          <label for="cvv">CVV</label>
          <input id="cvv" type="text" v-model="cvv" placeholder="123" />
        </div>
        <div class="form-group">
          <label for="cardholder-name">カード名義人</label>
          <input id="cardholder-name" type="text" v-model="cardholderName" placeholder="Taro Yamada" />
        </div>
        <button class="pay-button" @click="processPayment">支払う</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'paymentComponent',
    data() {
      return {
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        cardholderName: ''
      };
    },
    methods: {
      processPayment() {
        // 支払い処理のロジックを実装する
        // 例えば、フォームの入力値をサーバーに送信して決済を完了させるなど
        // 支払い処理のロジックを実装する

        // 支払いが成功した場合
        const paymentResult = 'payment-result'

        // 支払いが失敗した場合

        this.$router.push(paymentResult);
      }
    }
  };
  </script>
  
  <style scoped>
  .payment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .payment-form {
    width: 300px;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .pay-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pay-button:hover {
    background-color: #45a049
  }
    .pay-button:active {
    background-color: #3e8e41;
  }
</style>  