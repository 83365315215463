<template>
  <div class="login">
    <h2 class="login__title">ログイン</h2>
    <form @submit.prevent="login" class="login__form">
      <div class="login__form-group">
        <label for="username" class="login__label">ユーザーID:</label>
        <input id="username" type="text" placeholder="ユーザー名" v-model="username" required class="login__input">
      </div>
      <div class="login__form-group">
        <label for="password" class="login__label">パスワード:</label>
        <input id="password" type="password" placeholder="パスワード" v-model="password" required class="login__input">
      </div>
      <button class="login__button">ログイン</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login () {
      this.$cognito.login(this.username, this.password)
        .then(result => {
          this.$router.replace('/')
          setTimeout(() => {
            location.reload();
          }, 500);
          console.log(result);
        })
        .then(err => {
          this.error = err
        })
    }
  }
}
</script>
<style>
.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.login__title {
  font-size: 24px;
  margin-bottom: 20px;
}

.login__form {
  display: flex;
  flex-direction: column;
}

.login__form-group {
  margin-bottom: 10px;
}

.login__label {
  font-size: 16px;
}

.login__input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login__button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login__button:hover {
  background-color: #0056b3;
}
</style>