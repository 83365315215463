<template>
  <div class="partner-page">
    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;Partner&nbsp;&nbsp;&nbsp;/</h1>
    </div>

    <div class="artists">
      <h2 class="sub-title">ARTISTS</h2>
      <hr class="under-bar">
      <v-row>
        <v-col cols="4">
          <v-card class="mx-auto artist-card">
            <v-img 
              v-bind:src="require('../assets/artists/momoco.png')"
              class="artist-image"
              @click="showDialog1 = !showDialog1" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"        
            ></v-img>

            <v-card-title>
              桂川桃子
            </v-card-title>
            <v-card-subtitle>
              ファッションデザイナー<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog1" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    桂川桃子
                  </v-card-title>
                  <v-card-subtitle>
                    ファッションデザイナー
                  </v-card-subtitle>                  
                  <v-card-text>
                    momoco（桂川 桃子）は10年間、大手アパレルブランドでファッションデザイナーてして勤務。
                    2016年にTOMORROWLANDを退社。本格的に画家としての活動を開始。
                    2017年にラスベガスで毎年おこなわれる世界最大規模の展示会「MAGIC」にて
                    女性ライブペインターとして招待され巨大なアートをライブで作成した。<br>
                    デザイナーとして経験した色のバランスを活かした作品には、
                    常に新しいトレンドや流行色を取り入れ進化し続けるアートを目指している。
                    枠にとらわれないタッチは、壁画、パッケージデザイン、ライブペイントなど、
                    様々な形で表現されている。中でも、躍動感のあるアートにもかかわらず、
                    服をいっさい汚さず短時間で巨大な壁画をアートで埋め尽くすライブペイントは見るひとの心をひきつける。
                    国内外問わず、精力的にアートと向き合い発信を続けている。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog1 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto artist-card">
            <v-img 
              v-bind:src="require('../assets/artists/shin-rei.png')"
              class="artist-image"
              @click="showDialog2 = !showDialog2" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img>

            <v-card-title>
              真澪
            </v-card-title>
            <v-card-subtitle>
              書道家<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog2" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    真澪
                  </v-card-title>
                  <v-card-subtitle>
                    書道家
                  </v-card-subtitle>

                  <v-card-text>
                    7歳から書道を始める。読売書法展や日本書芸院展などで数々の賞を受賞。22歳師範免許取得。
                    心華書道教室を開講。書道競書『鈍愚理』発行、 編集に携わる。<br>
                    書道パフォーマンスに関しては、日本のみならず、フランス・ルーヴル美術館での書道パフォーマンスなど
                    ヨーロッパ、アメリカ、アジアでのパフォーマンス、ワークショップ、個展等グローバルに活動している。<br>
                    近年の実績として、Hugo Boss2021s/s Collectionの【ヒューゴ】の文字を揮毫。
                    アーティスト､『ケツメイシ』のロゴを揮毫。生前葬TV 又吉直樹の【生前葬のすゝめ】
                    【東京五輪選考会 柔道グランドスラム大阪】などの番組タイトルロゴも担当。<br>
                    ドリフトの世界大会『FIA Intercontinental Drifting Cup Tokyo Drift 2018』にてパフォーマンスの実現。
                    日本で開催された世界的トークショーTED×Nambaでのオープニング書道パフォーマンス｡そして世界初となった、
                    書道家 真澪 × ウォーターライトグラフィティのコラボレーションを実施するなど書の新たな可能性や分野の開拓も積極的に行っている。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog2 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto artist-card">
            <v-img 
              v-bind:src="require('../assets/artists/kubota.png')"
              class="artist-image"
              @click="showDialog3 = !showDialog3" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img>            

            <v-card-title>
              窪田望
            </v-card-title>
            <v-card-subtitle>
              AIアーティスト<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog3" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    窪田望
                  </v-card-title>
                  <v-card-subtitle>
                    AIアーティスト
                  </v-card-subtitle>
                  <v-card-text>
                    米国NY州生まれ。慶應義塾大学総合政策学部卒業。大学在学中の19歳の時に起業し、現在18年目。
                    東京大学大学院工学系研究科技術経営戦略学専攻グローバル消費インテリジェンス寄附講座 / 松尾研究室（GCI 2019 Winter）を修了。
                    米国マサチューセッツ工科大学のビジネススクールであるMIT スローン経営⼤学院で
                    「Artificial Intelligence: Implications for Business Strategy」を修了。
                    2019年、2020年には3万7000名の中から日本一のウェブ解析士（Best of the Best）として2年連続で選出され殿堂入り。
                    NFT鳴門美術館でAIを使った絵画展「Deep Art展」を開催。羽田イノベーションシティーで「題名のないAI絵画展」開催。
                    価値デザインコンテストにて内閣総理大臣賞受賞。渋谷ヒカリエにてMONSTER EXHIBITION 2022最優秀賞受賞。
                    SynchoArt Foundation【saf】所属。NHK、テレビ東京、日経BP、日本経済新聞など出演多数。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog3 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto artist-card">
            <v-img 
              v-bind:src="require('../assets/artists/nampou.png')"
              class="artist-image"
              @click="showDialog4 = !showDialog4" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              南法聖観
            </v-card-title>
            <v-card-subtitle>
              仏画家<br><br>
            </v-card-subtitle>        
            <v-dialog v-model="showDialog4" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    南法聖観
                  </v-card-title>
                  <v-card-subtitle>
                    仏画家
                  </v-card-subtitle>   
              
                  <v-card-text>
                  写仏は一切行わず、降臨された御仏様や御神体を描く仏画師である。<br>
                  40年前にあるお寺から法力を授かり、脳裏に浮かぶ7色の光や特殊な記号で
                  占術や護摩祈祷も行う超能力者である。遠く離れた海外の方の心理も読み取る事が出来る為、
                  日本のみならず海外ファンも多い。<br>
                  ■2019.9/23〜9/25<br>
                  南法聖観40周年記念仏画展-まほろばのみ仏さまたち-<br>
                  奈良 東大寺 東大寺総合文化センター 小ホール<br>
                  ■過去の展示 (個展)<br>
                  2016年 高野山金剛峰寺<br>
                  2017年 横浜赤レンガ倉庫<br>
                  2018年 築地本願寺<br>
                  2018年12月11日 急性骨髄性白血病と診断され闘病<br>
                  2019年4月3日 寛解 寛解後療法1回で抗がん剤治療を中止 地固め1回。
                  『無駄な治療は御免被る！』自らの意思で治療を中止。再び生かされた命で天命を全うする。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog4 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto artist-card">
            <v-img 
              v-bind:src="require('../assets/artists/youko.png')"
              class="artist-image"
              @click="showDialog5 = !showDialog5" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 

            <v-card-title>
              ヨウコ フラクチュール
            </v-card-title>

            <v-card-subtitle>
              fRAum®︎<br>
              アートカリグラファー・アーティスト
            </v-card-subtitle>

            <v-dialog v-model="showDialog5" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    ヨウコ フラクチュール
                  </v-card-title>
                  <v-card-subtitle>
                    fRAum®︎<br>
                    アートカリグラファー・アーティスト
                  </v-card-subtitle>                  
                  <v-card-text>
                    カリグラフィーの本場の西ヨーロッパ(イギリス、ルクセンブルク、ドイツ)に10年以上在住し、
                    エリザベス女王を顧客に持ち、 ロード・オブ・ザ・リングの蔵書を担当したアレキサンドラ・レメス女史に師事。
                    美文字を書くカリグラフィーを、本物の技術とセンスでアートへと昇華した唯一無二の
                    「アートカリグラフィー」は、老若男女問わず好まれ、特に知性と感性の高い方ほど関心が強く、
                    熱狂的なファンも多い。 2019年のParisの国際ファッショントレードショウでも、
                    世界中から集まったアルファベットを母国語とする多くのバイヤーや エージェント、デベロッパーから賞賛された。
                    ヨウコ フラクチュールならではの、可愛いけどお洒落で大人っぽいエッセンスは、
                    ハイブランドを中心に幅広い業種から求められている。
                    ARS scribendi ヨーロッパカリグラフィー協会会員。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog5 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto artist-card">
            <v-img 
              v-bind:src="require('../assets/artists/kumono.png')"
              class="artist-image"
              @click="showDialog6 = !showDialog6" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              雲野一鮮
            </v-card-title>

            <v-card-subtitle>
              fRAum®︎<br>
              アートディレクター・空間デザイナー
            </v-card-subtitle>

            <v-dialog v-model="showDialog6" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    雲野一鮮
                  </v-card-title>
                  <v-card-subtitle>
                    fRAum®︎<br>
                    アートディレクター・空間デザイナー
                  </v-card-subtitle>                  
                  <v-card-text>
                    ファッション繊維業界を中心とした、商環境の空間デザインやブランディング、
                    VMD(ビジュアルマーチャンダイジング)を 生業とし、fRAum®(フラウム)では、
                    アートディレクション、空間デザイン、プロダクトデザイン、マネジメントを担当。<br>
                    日本が世界に誇る伝統や手仕事、先端技術や素材をアートカリグラフィーで表現し、
                    空間からファッション、インテリア、 プロダクト、インスタレーションまで幅広い領域で展開し、
                    企業やハイブランドのブランディングを次々に手掛けている。<br>
                    カリグラフィーの世界は、何百年と紙とペンとインクの時代が続いてきたが、
                    fRAum®(フラウム)結成以後、有機EL、金属線 織物、アクリルと光の拡散インクなど、
                    今この時代だからこそできる表現によって、カリグラフィー界に革命を起こしている。 <br>
                    クモノデザイン株式会社 代表取締役、日本商環境デザイン協会正会員、日本空間デザイン協会正会員、
                    日本ビジュアル マーチャンダイジング協会正会員、日本ディス プレイクリエイター協会アンバサバダー、
                    東京デザイン専門学校非常勤講師。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog6 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- <div class="sponsor">
      <h2 class="sub-title">SPONSORS</h2>
      <hr class="under-bar">
      <v-container>
        <v-row align="center">
          <v-col v-for="(image, index) in imageList" :key="index" cols="3">
              <v-img
                :src="require(`../assets/sponsor/${image}.jpg`)"
                class="sponsor-image"
              ></v-img>
            
          </v-col>
        </v-row>
      </v-container>

    </div> -->
  </div>

  

</template>
  
<script>
export default {
  name: 'PartnerComponent',
  data () {
    return {
      showDialog1: false,
      showDialog2: false,
      showDialog3: false,
      showDialog4: false,
      showDialog5: false,
      showDialog6: false,
      hoverCard: false,
      imageList: [
        'horipro',
        'o-blood',
        'drive-x',
        'high-place',
        'ukrine',
        'cool-japan',
        'wakuwaku-ka',
        'paralym-art',
        'jpa',
        'rakuza',
      ],
    }
  }

};
</script>
  
<style scoped>
.partner-page {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.artists{
  margin-bottom: 100px;
}

.artist-card:hover {
  filter: brightness(0.8); 
}

.artist-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 1.5px solid #000000;
  overflow: hidden;
  box-sizing: border-box;
}

/* .sponsor-card {
  background-color: #ffffff; 
  text-align: center;
  border: none !important;
} */

.sponsor-image{
  width: auto;
  height: auto;
  margin: 0 auto; /* 画像を水平方向に中央揃え */
  border: none !important;
}


</style>