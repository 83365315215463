<template>
    <div>
        <MypageComponent />
    </div>
</template>
  
<script>
import MypageComponent from '@/components/mypage.vue';

export default {
    name: 'MypagePage',
    components: {
        MypageComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>