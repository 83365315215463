<template>
    <div>
        <MembershipComponent />
    </div>
</template>
  
<script>
import MembershipComponent from '@/components/member/membership';

export default {
    name: 'MembershipPage',
    components: {
        MembershipComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>