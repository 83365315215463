<template>
    <div>
        <PolicyComponent />
    </div>
</template>
  
<script>
import PolicyComponent from '@/components/policy';

export default {
    name: 'PolicyPage',
    components: {
        PolicyComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>