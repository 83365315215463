<template>
  <div class="container">
  <v-card >
    <v-layout>
      <v-app-bar color="grey-lighten-1" prominent app>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>SynchroArt Foundation</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn class="icon" variant="text" icon="mdi-home-city" to="/home"></v-btn>
        <v-btn class="icon" variant="text" icon="mdi-instagram" href="https://www.instagram.com/synchroart.foundation/?igshid=YmMyMTA2M2Y%3D" ></v-btn>
        <v-btn class="icon" variant="text" icon="mdi-twitter" href="https://twitter.com/saf_gallery" ></v-btn>
        <v-btn class="icon" variant="text" icon="mdi-chat" href="https://line.me/R/ti/p/@bwb1242o" ></v-btn>

        
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" location="top" temporary>
        <v-list>
          <v-list-item prepend-icon="mdi-domain" title="Aboutus" value="aboutus" to="/aboutus"></v-list-item>
          <v-list-item prepend-icon="mdi-handshake" title="Partner" value="partner" to="/partner"></v-list-item>
          <v-list-item prepend-icon="mdi-palette" title="Service" value="service" to="/service"></v-list-item>
          <v-list-item prepend-icon="mdi-account-group" title="Society" value="society" to="/society"></v-list-item>
          <v-list-item prepend-icon="mdi-cash-multiple" title="Contribution" value="Contribution" to="/membership-list"></v-list-item>  
        </v-list>
      </v-navigation-drawer>

    </v-layout>
  </v-card>
</div>
</template>

<script>
import cognito from '@/service/cognito';
export default {
  created() {
    // 初回表示時に実行したい処理をここに記述します
    cognito.isAuthenticated()
      .then(session => {
        console.log(session);
        this.isAuthenticated = true;

      })
      .catch(session => {
        console.log(session);
        this.isAuthenticated = false;
      });
  },
  name: "TestPage",
  data() {
    return {
      // ログイン状態を管理するフラグ
      isAuthenticated: false,
      drawer: false,
      group: null,
    };
  },

  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    login() {
      this.$router.replace('/login')
      this.isAuthenticated = false;
    },
    logout() {
      this.$router.replace('/logout')
      this.isAuthenticated = false;
    },
    signup() {
      this.$router.replace('/signup')
    }
  },
};
</script>
<style scoped>
@media (max-width: 767px) {
.v-toolbar-title {
    flex: initial !important;
    font-size: 18px !important;
}

.v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 0px) !important;
  height: calc(var(--v-btn-height) + 0px) !important;
}
}

@media (max-width: 430px) {
.v-toolbar-title {
    flex: initial !important;
    font-size: 14px !important;
}

.v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) - 5px) !important;
  height: calc(var(--v-btn-height) - 5px) !important;
}
}

</style>