<template>
  <div>
    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;About Us&nbsp;&nbsp;&nbsp;/</h1>
    </div>
    <div class="gazou">
      <v-img v-bind:src="require('../assets/daichi.jpg')"></v-img>
    </div>

    <div class="description">
      <div>
        <h2 class="sub-title">MISSION</h2>
        <hr class="under-bar">
        <p>一般財団法人SynchroArt Foundationは、
          芸術文化の振興に関する活動を行い、優れた美術文化芸術家の育成を行うとともに、
          芸術文化の啓蒙及び促進により、人々の創造性や表現力を育み、
          さらに、他者への理解と尊重し合い多様性を受け入れることができる
          心豊かな社会の形成に寄与することを目的としています。</p>
      </div>
      <div>
        <h2 class="sub-title">PROJECTS</h2>
        <hr class="under-bar">
        <p class="single-line">芸術文化芸術家に対する活動支援事業</p>
        <p class="single-line">芸術文化活動による他業種との交流および他業種への支援事業</p>
        <p class="single-line">芸術文化に関する展覧会の開催事業</p>
        <p class="single-line">芸術文化活動による地域振興事業</p>
        <p class="single-line">芸術文化活動による社会貢献事業</p>
        <p class="single-line">その他この法人の目的を達成するために必要な事業</p>
      </div>
    </div>

    <div class="main">
      <h2 class="sub-title">DIRECTORS</h2>
      <hr class="under-bar">
      <v-row>
        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/daichi5.jpg')"
              class="director-image"
              @click="showDialog1 = !showDialog1" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img>

            <v-card-title>
              坂本大地
            </v-card-title>

            <v-card-subtitle>
              一財) SynchroArt Foundation 代表理事<br>
              合同会社Synchro Art 代表社員<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog1" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    坂本大地
                  </v-card-title>
                  <v-card-subtitle>
                    一財) SynchroArt Foundation 代表理事<br>
                    合同会社Synchro Art 代表社員<br><br>
                  </v-card-subtitle>                  
                  <v-card-text>
                  "ウォーターボーイズ"への出演を経て、芸術の道へ。<br>
                  大統領や王族らを含む多くのコレクターやアーティスト達との出逢いから数々の作品を生み出す。<br>
                  アーティスト支援をベースに、障がい者支援・貧困国支援・地方創生支援などの救済活動に資金を充てるアート財団safを創設。<br>
                  自身の作品売上の大半を寄付。<br>
                  銀座最大の商業施設GINZA SIXにアーティスト達との社交場となる『Saf Gallery』をOPEN。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog1 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/kento.jpg')"
              class="director-image"
              @click="showDialog2 = !showDialog2" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img>

            <v-card-title>
              Kento Mori
            </v-card-title>

            <v-card-subtitle>
              ケーエムワン株式会社<br><br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog2" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Kento Mori
                  </v-card-title>
                  <v-card-subtitle>
                    ケーエムワン株式会社<br><br><br>
                  </v-card-subtitle>                  
                  <v-card-text>
                  マイケル ・ ジャクソンやマドンナ、 世界が認めたダンスアーティスト。<br>
                  他にもクリス ・ ブラウンや Usher 等の世界的なトップアーティストの専属ダンサーとなり、 世界 最高峰の舞台やツアーで活躍。<br>
                  グラミー賞をはじめとする数々のアワードへの出演も果たし、 これまで全世界 5 大陸 50 カ国 200 以上の主要都市においてパフォーマンスを行う。<br>
                  近年では 70 曲以上ものオリジナル楽曲の制作と、 世界的有名なデジタルアート集団 「Mom ent Factory」 との共同プロジェクトで開発した最先端 AR を LIVE で生成するパフォーマンス 等、
                  世界に未だない新しい表現を追求している。<br>
                  2021 年より様々な自治体と “日本を世界へ” プロジェクトを展開している。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog2 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/fujikawa.jpg')"
              class="director-image"
              @click="showDialog3 = !showDialog3" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img>            

            <v-card-title>
              藤川靖彦
            </v-card-title>

            <v-card-subtitle>
              一般社団法人花絵文化協会 代表理事<br>
              株式会社インフィオラータ・アソシエイツ<br>
              代表取締役社長
            </v-card-subtitle>

            <v-dialog v-model="showDialog3" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    藤川靖彦
                  </v-card-title>
                  <v-card-subtitle>
                    一般社団法人花絵文化協会 代表理事<br>
                    株式会社インフィオラータ・アソシエイツ<br>
                    代表取締役社長
                  </v-card-subtitle>                  
                  <v-card-text>
                  1961 年東京生まれ。日本大学芸術学部演劇学科卒業。<br>
                  限りある命=Ephemeral をテーマに、国内外において花や キャンドル等を使ったエフェメラル・アートを創作。 大地をキャンバスに花びらで描く花絵「インフィオラータ」の
                  日本の第一人者で、現在まで21年間に国内外約 400ヵ所で1500を超える作品を創作・プロデュースした。海外では歌舞伎絵を花で再現する「花歌舞伎」は、世界 20 ヶ国 地域で創作されている。<br>
                  2015 年 6月、スペインでの創作を、毎日放送「情熱大陸」が密着取材を行い大反響を呼んだ。これを機にテレビ・ラジオ・ 新聞・雑誌・WEB 等に数多く出演中。また現在在京 FM 局 interfm897
                  にて番組 DJ も行う。<br>
                  （毎週土曜日 16:43～16:58 放送「Flower's YELL」)<br>
                  エンジン０１文化戦略会議会員／エフェメラル・アート国際連盟理事
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog3 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/ishita.jpg')"
              class="director-image"
              @click="showDialog4 = !showDialog4" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              石多 未知行
            </v-card-title>

            <v-card-subtitle>
              一財）プロジェクションマッピング協会 代表理事<br>
              カラーズクリエーション（株）代表取締役<br>
              （株）NIGHT WAVE 代表取締役
            </v-card-subtitle>
            
            <v-dialog v-model="showDialog4" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    石多 未知行
                  </v-card-title>
                  <v-card-subtitle>
                    一財）プロジェクションマッピング協会 代表理事<br>
                    カラーズクリエーション（株）代表取締役<br>
                    （株）NIGHT WAVE 代表取締役
                  </v-card-subtitle>                  
                  <v-card-text>
                  国内でいち早くプロジェクションマッピングを手掛け始め、<br>
                  国際大会や企画展のプロデュース、舞台やライブの演出、そしてビジネスから地域創成セミナーやワークショップなどの人材育成まで展開。<br>
                  今年で第10回を迎える、本大会の生みの親でもあり、世界的なプロジェクションマッピングの識者。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog4 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/katsuya.jpg')"
              class="director-image"
              @click="showDialog5 = !showDialog5" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 

            <v-card-title>
              KATSUYA OOE
            </v-card-title>

            <v-card-subtitle>
              ファッションデザイン修士<br><br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog5" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    KATSUYA OOE
                  </v-card-title>
                  <v-card-subtitle>
                    ファッションデザイン修士<br><br><br>
                  </v-card-subtitle>                  
                  <v-card-text>
                  卒業後ロンドンに渡り、 VivienneWestwood社デザイナー、パリコレクション、ミラノコレクションを手掛ける。 <br>
                  ミラノでフリーランスで多数のラグジュアリーブランド、アトリエ、企業のコレクションを手掛ける。<br>
                  2008年KATSUYA DESIGN OFFICE設立 <br>
                  「OOE」BAGブランド<br>
                  パリコレクション発表<br>
                  OOE JEWELYを発表。<br>
                  デザイン事務所として多数のブランドとデザイン業務提携。 空間デザイン、インテリア、ブライダルデザイン、 <br>
                  ミシュラン店のユニホームなど多数手掛ける。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog5 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/sugino.jpg')"
              class="director-image"
              @click="showDialog6 = !showDialog6" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              杉野宣雄
            </v-card-title>

            <v-card-subtitle>
              ふしぎな花倶楽部会長／世界押花芸術協会会長<br>
              レカンフラワー協会会長<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog6" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    杉野宣雄
                  </v-card-title>
                  <v-card-subtitle>
                    ふしぎな花倶楽部会長／世界押花芸術協会会長<br>
                    レカンフラワー協会会長<br><br>
                  </v-card-subtitle>                  
                  <v-card-text>
                  1966年、福岡県大牟田市生まれ。<br>
                  日本を代表する押し花作家であり、ボタニックアート（押し花、ネイチ ャープリント、レカンフ ラワー、アルケミックアート、ネイチャーコラージュ、押し花クチュール、フォトスタイル押し
                  花、花びらコラージュ、ドリームフラワー、エバーアレンジメントなど植物を生かした芸術の総称）を提唱し、研究・創作・普及など幅広く活動している。<br>
                  ●アメリカ最大の花の祭典「フィラデルフィアフラワーショー」の押し花コンテストで、日本人初のグランプリを受賞。[1998]<br>
                  ●海外の押し花作家との交流を積極的に行い、世界押花芸術協会を設立。[1999]<br>
                  ●日英政府交流事業「JAPAN2001」の企画で、日本を代表する押し花作家として、イギリス のケンブリッジ大学植物園で個展開催。[2001]<br>
                  ●日本ヴォーグ社と提携し、約3万人の会員を有する押し花愛好者団体「ふしぎな花倶楽部」 の会長として、押し花文化の普及に尽力。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog6 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/kazu.jpg')"
              class="director-image"
              @click="showDialog7 = !showDialog7" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              藤原一秀
            </v-card-title>

            <v-card-subtitle>
              株式会社ネクシィーズ・ワンダーウォール代表取締役<br>
              株式会社キックアス・エンターテインメント代表取締役<br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog7" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    藤原一秀
                  </v-card-title>
                  <v-card-subtitle>
                    株式会社ネクシィーズ・ワンダーウォール代表取締役<br>
                    株式会社キックアス・エンターテインメント代表取締役<br>
                  </v-card-subtitle>                  
                  <v-card-text>
                  2016年当時、世界初で最新鋭3Dホログラム技術を発信し、常に色褪せない最先端の演出力を心掛け、完全なメイド・イン・ジャパンブランドで世界へ発信し続けています。<br>
                  3Dホログラム界の先駆者として、日本やアジア、また同技術の先進国であるイギリスやアメリカに劣らないエンターテインメントを皆様にお届けできるように努めます。<br>
                  未来の世界を明るく、人々の日常に“わくわく”を。<br>
                  「Creator is King」 の精神で 「日本から世界へ」。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog7 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/yamashita.jpg')"
              class="director-image"
              @click="showDialog8 = !showDialog8" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              山下翔一
            </v-card-title>

            <v-card-subtitle>
              ペライチ 創業者・会長<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog8" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    山下翔一
                  </v-card-title>
                  <v-card-subtitle>
                    ペライチ 創業者・会長<br><br>
                  </v-card-subtitle>                
                  <v-card-text>
                  ペライチ 創業者・会長／オリパラ首長連合 企業代表／応援村 広報部長／おうえんフェス 会長／環境省 地域循環共生圏
                  アドバイザー／複数自治体のアドバイザー／大学教授など100以上の法人・プロジェクトの代表・役員・顧問等
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog8 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto director-card">
            <v-img 
              v-bind:src="require('../assets/ishimaru.jpg')"
              class="director-image"
              @click="showDialog9 = !showDialog9" 
              @mouseover="hoverCard = true"
              @mouseleave="hoverCard = false"            
            ></v-img> 
            <v-card-title>
              石丸千琴
            </v-card-title>

            <v-card-subtitle>
              合同会社Collet 代表社員<br><br>
            </v-card-subtitle>

            <v-dialog v-model="showDialog9" max-width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    石丸千琴
                  </v-card-title>
                  <v-card-subtitle>
                    合同会社Collet 代表社員<br><br>
                  </v-card-subtitle>                  
                  <v-card-text>
                  017年に立命館大学卒業、17～2021年まで大手コンサルティングファームに所属。広報・マーケティングという領域がDX推進の鍵であるとの考えから数々のtoB企業の社内活性化に尽力し売り上げの増大などに貢献してきた。
                  独立後は一部上場企業のDX戦略を複数手がける。
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="showDialog9 = false">
                      閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="description">
      <h2 class="sub-title">INFORMATION</h2>
      <hr class="under-bar">
      <table>
        <tbody>
          <tr>
            <td>名称</td>
            <td>一般財団法人SynchroArt Foundation(saf)</td>
          </tr>
          <tr>
            <td>設立</td>
            <td>2019年2月21日</td>
          </tr>
          <tr>
            <td>代表理事</td>
            <td>坂本大地</td>
          </tr>
          <tr>
            <td>主要活動拠点</td>
            <td>GINZA SIX</td>
          </tr>
          <tr>
            <td>メールアドレス</td>
            <td>contact@synchroart.or.jp</td>
          </tr>
          <tr>
            <td>TEL</td>
            <td>03-6206-4614</td>
          </tr>
          <tr>
            <td>FAX</td>
            <td>03-6701-7524</td>
          </tr>
          <tr>
            <td>運営会社</td>
            <td>合同会社Synchro Art</td>
          </tr>
          <tr>
            <td></td>
            <td>株式会社SynchroArt</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="description">
      <h2 class="sub-title">古物商許可</h2>
      <hr class="under-bar">
      <table>
        <tbody>
          <tr>
            <td>名称</td>
            <td>合同会社Synchro Art</td>
          </tr>
          <tr>
            <td>許可公安委員会</td>
            <td>東京都公安委員会</td>
          </tr>
          <tr>
            <td>許可番号</td>
            <td>301062219434</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>


</template>

  


<script>
export default {
  name: 'AboutusComponent',
    data () {
    return {
      showDialog1: false,
      showDialog2: false,
      showDialog3: false,
      showDialog4: false,
      showDialog5: false,
      showDialog6: false,
      showDialog7: false,
      showDialog8: false,
      showDialog9: false,
      hoverCard: false,
    }
  }
};

</script>
  
<style >
.zentai {
  background-color: lightgray;
}

.page-title {
  margin-top: 150px;
  text-align: center;
}

.sub-title {
  font-size: 24px;
  margin-top: 100px;
  margin-bottom: 10px;
}

.under-bar {
  border: none; 
  background-color: #000; 
  height: 1px; 
  width: 70px; 
  margin-bottom: 50px;
}

.description {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.gazou {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.main {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.director-card:hover {
  filter: brightness(0.8); 
}

.director-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 1.5px solid #000000;
  overflow: hidden;
  box-sizing: border-box;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

/* table, th, td {
  border: 1px solid #ddd;
} */


th, td {
  padding: 8px;
  text-align: left;
}

.single-line {
  white-space: nowrap; 
  overflow: hidden;    
  text-overflow: ellipsis; 
  
}

/* スマートフォン向けのテキストサイズ調整 */
@media (max-width: 767px) {

      table, th, td {
      /* display: block; */
      width: 100%;
    }

    th, td {
      padding: 2px;
    }
}

</style>