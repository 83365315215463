<template>
  <div class="society-page">
    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;Society&nbsp;&nbsp;&nbsp;/</h1>
    </div>
    <!-- <div class="message">
        アートを支援するのではなく、世界の伝統文化芸能を一歩先へ。<br>
        あなたの寄付が未来のアーティストを生み出し、<br>
        誰かの「アーティスト」という人生の選択肢をひとつ増やすきっかけになります。<br>
        あたなが想う未来のためにご参加ください。
    </div> -->

    <div class="card">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          class="mx-auto card-item"
          max-width="600"
          href="https://square.link/u/xmyKCSgQ"
        >
          <v-img v-bind:src="require('../assets/kojin.jpg')"></v-img>

          <v-card-text>
            <h2 class="text-h6 primary--text">
              個人会員
            </h2>
              個人会員は、safの掲げるアート社会活動への寄付、及び、活動を通して広がるsaf会員コミュニティの充実を目的としています。
              個人会員には特定の特典やサービスが提供されます。
          </v-card-text>

          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#036358"      
            >
              <v-btn>寄付する</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
    </div>

    <div class="card card-item">
      <v-hover >
        <template v-slot:default="{ hover }">
          <v-card
            class="mx-auto"
            max-width="600"
            href="https://square.link/u/hH8UjS71"
          >
            <v-img v-bind:src="require('../assets/gojokai.jpg')"></v-img>

            <v-card-text>
              <h2 class="text-h6 primary--text">
                互助会
              </h2>
                互助会は、safアーティスト同士が相互に支援し合い、一人では実現しえない発信力や活動範囲を各アーティストが持つことを目的としています。
                互助会に参加すると、以下が可能となります。<br>
                ①saf HP内に作品の掲示<br>
                ②コンペへの参加<br>
                ③GINZA SIX他、saf主催・提携ギャラリーでのイベントや企画展の開催
            </v-card-text>

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                color="#036358"
              >
                <v-btn>寄付する</v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </div>

  </div>

</template>
  





  
<script>
export default {
  name: 'SocietyComponent',
  data: () => ({
    overlay: false,
  })
};
</script>
  
<style scoped>
.message{
  width: 600px;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  line-height: 2.0;
}

.card {
  margin-top: 100px;
  margin-bottom: 50px;
}

.card-item:hover {
  filter: brightness(0.8); 
}
</style>