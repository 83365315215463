<template>
    <div>
        <SocietyComponent />
    </div>
</template>
  
<script>
import SocietyComponent from '@/components/society';

export default {
    name: 'SocietyPage',
    components: {
        SocietyComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>