<template>
    <div>
        <AboutusComponent />
    </div>
</template>
  
<script>
import AboutusComponent from '@/components/aboutus';

export default {
    name: 'AboutusPage',
    components: {
        AboutusComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>