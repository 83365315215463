<template>
    <div>
        <MembershipListComponent />
    </div>
</template>
  
<script>
import MembershipListComponent from '@/components/member/membershipList';

export default {
    name: 'MembershipListPage',
    components: {
        MembershipListComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>