<template>
    <div>
        <PaymentComponent />
    </div>
</template>
  
<script>
import PaymentComponent from '@/components/payment/payment';

export default {
    name: 'PaymentPage',
    components: {
        PaymentComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>