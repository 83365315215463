vue

<template>
  <!-- <div>
    <h2 class="headline grey lighten-2">お問い合わせ</h2>
    <form @submit.prevent="submitForm" class="contact-form">
      <div class="form-group">
        <label for="name" class="form-label">お名前</label>
        <input v-model="name" type="text" id="name" required class="form-input">
      </div>
      <div class="form-group">
        <label for="email" class="form-label">メールアドレス</label>
        <input v-model="email" type="email" id="email" required class="form-input">
      </div>
      <div class="form-group">
        <label for="message" class="form-label">お問い合わせ内容</label>
        <textarea v-model="message" id="message" required class="form-textarea"></textarea>
      </div>
      <button type="submit" class="btn-primary">送信</button>
    </form>
  </div> -->
  <div>
    古物商許可
    東京都公安委員会
    第301062219434
    合同会社Synchro Art<br>
    contact@synchroart.or.jp<br>
    Tel: 03-6206-4614   Fax: 03-6701-7524
  </div>
</template>

  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'contactComponent',
    setup() {
      const name = ref('');
      const email = ref('');
      const message = ref('');
  
      function submitForm() {
        // ここでフォームの送信処理を行います
        console.log('フォームが送信されました');
        console.log('お名前:', name.value);
        console.log('メールアドレス:', email.value);
        console.log('お問い合わせ内容:', message.value);
        
        // 送信後にフォームをリセットする場合は以下のコメントアウトを解除します
        // name.value = '';
        // email.value = '';
        // message.value = '';
      }
  
      return {
        name,
        email,
        message,
        submitForm
      };
    }
  }
  </script>
  
  <style>
  .headline {
    font-size: 24px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .grey {
    background-color: #f2f2f2;
  }
  
  .lighten-2 {
    opacity: 0.8;
  }
  
  .contact-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .form-input,
  .form-textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }
  
  .btn-primary {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  </style>
  