<template>
  <div>
    <v-toolbar
      color="grey-lighten-1"
      prominent
      height="120"
    >
      <v-row justify="center" no-gutters>
    
      <v-btn
        color="white"
        variant="text"
        class="mx-2"
        rounded="xl"
        to="/"
        >ホーム</v-btn>
        <v-btn
        color="white"
        variant="text"
        class="mx-2"
        rounded="xl"
        to="/privacy-policy"
        >プライバシーポリシー</v-btn>
        <!-- <v-btn
        color="white"
        variant="text"
        class="mx-2"
        rounded="xl"
        to="/contact"
        >お問い合わせ</v-btn> -->
        <v-btn
        color="white"
        variant="text"
        class="mx-2"
        rounded="xl"
        to="/terms"
        >会員規約</v-btn>
      <v-col class="text-center mt-4" cols="12">
        &copy; 2023 SynchroArt Foundation
      </v-col>

      
    </v-row>
    </v-toolbar>

  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>