<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      会員規約
    </v-card-title>

    <v-card-text>
      <div class="terms">
        <h3 class="section-title">第1条(目的)</h3>
        <p>
          この規約は、一般財団法人SynchroArt
          Foundation(以下「本財団」という。)が絵画やデジタルアート、映像作品、その他芸術作品(以下「アート作品」と言う)に関する制作および販売、アート作品に関する国内及び海外における販売・企画等の業務提携並びに工房アトリエ管理、マネジメント、コンサルティングこれらに関連して提供されるサービス(以下「本事業」という。)の会員及び会費等に関し、必要事項を定めることを目的とする。
        </p>

        <h3 class="section-title">第2条(会員)</h3>
        <p>
          本財団定款(以下「定款」という。)第3条に定める本財団の目的と本事業の趣旨に賛同し、本規約第3条に定める入会手続きを経て承認された個人または法人を会員とする。
        </p>

        <h3 class="section-title">第3条(入会)</h3>
        <p>
          入会希望者は、「会費決済方法の登録」「入会者情報登録」(以下、「入会申込書」という)を完了した時点をもって、入会を申し込んだとみなす。前項の申込があったとき、理事長は、第4条の規定に従い審査を行い、不承認の際は入会希望者に対し通知する。
        </p>

        <h3 class="section-title">第4条(承認の基準)</h3>
        <p>
          次の各号に定める事由に該当する場合、入会を承認しないことがある。
        </p>
        <li>本財団の目的に賛同していないとき</li>
        <li>本事業の趣旨に賛同していないとき</li>
        <li>入会申込書の記載事項に虚偽記載があるとき</li>
        <li>法令または公序良俗に反する行為を行っているとき</li>
        <li>その他、理事長が入会を適当でないと判断した場合</li>
        <h3 class="section-title">第5条(会費)</h3>
        <p>
          会員は、本財団が定める会費を支払うものとする。会費の支払い方法、期限等は別途定める規則による。
        </p>

        <h3 class="section-title">第6条(個人情報の取扱い)</h3>
        <p>
          本財団は、会員の個人情報を適切に取扱うことに努める。詳細は別途定めるプライバシーポリシーによる。
        </p>

        <h3 class="section-title">第7条(退会)</h3>
        <p>
          会員が退会を希望する場合は、本財団が定める手続きに従い、退会手続きを行うものとする。
        </p>

        <h3 class="section-title">第8条(規約の変更)</h3>
        <p>
          本財団は、会員に通知することなく、本規約を変更することができるものとする。変更後の規約は、本財団のウェブサイト等で公開された時点から効力を生じるものとする。
        </p>

        <h3 class="section-title">第9条(準拠法および管轄裁判所)</h3>
        <p>
          本規約の解釈にあたっては、日本法を準拠法とする。また、本規約に起因または関連して生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とする。
        </p>
      </div>
    </v-card-text>

    <v-divider></v-divider>

  </v-card>
</template>
  
<script>
export default {
  name: 'TermsComponent',
};
</script>
  
<style ></style>