<template>
    <div>
        <SignupComponent />
    </div>
</template>
  
<script>
import SignupComponent from '@/components/certification/signup';

export default {
    name: 'SignupPage',
    components: {
        SignupComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>