<template>
    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;Contribution&nbsp;&nbsp;&nbsp;/</h1>
    </div>
  <!-- <div class="card">
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="mx-auto card-item"
        max-width="500"
        href="https://square.link/u/xmyKCSgQ"
      >
        <v-img v-bind:src="require('../../assets/kojin.jpg')"></v-img>

        <v-card-text>
          <h2 class="text-h6 primary--text">
            個人会員
          </h2>
            個人会員とは、個人が加入する会員です。<br>
            個人会員には特定の特典やサービスが提供されます。
        </v-card-text>

        <v-fade-transition>
          <v-overlay
            v-if="hover"
            absolute
            color="#036358"      
          >
            <v-btn>寄付する</v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
  </div>
  <div class="card card-item">
    <v-hover >
      <template v-slot:default="{ hover }">
        <v-card
          class="mx-auto"
          max-width="500"
          href="https://square.link/u/hH8UjS71"
        >
          <v-img v-bind:src="require('../../assets/gojokai.jpg')"></v-img>

          <v-card-text>
            <h2 class="text-h6 primary--text">
              互助会
            </h2>
              互助会は、メンバー同士が支援し合い、共同の利益を追求する組織です。
              互助会に参加することで、経済的な安定や相互のサポートを受けることができます。
          </v-card-text>

          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#036358"
            >
              <v-btn>寄付する</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
  </div> -->
  <div class="card card-item">
    <v-hover >
      <template v-slot:default="{ hover }">
        <v-card
          class="mx-auto"
          max-width="600"
          href="https://square.link/u/4QqZp2Gl"
        >
          <v-img v-bind:src="require('../../assets/kifu.jpg')"></v-img>

          <v-card-text>
            <h2 class="text-h6 primary--text">
              寄付
            </h2>
              少額の寄付も大きな意味を持ち、アート業界への貢献の一環となります。
          </v-card-text>

          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#036358"
            >
              <v-btn>寄付する</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
    
  </div>
</template>
  
<script>
  export default {
    data: () => ({
      overlay: false,
    }),
  }
</script>

<style scoped>
.card {
  margin-top: 100px;
  margin-bottom: 100px;
}

.card-item:hover {
  filter: brightness(0.8); 
}
</style>
