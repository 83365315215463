<template>
    <div>
        <TermsComponent />
    </div>
</template>
  
<script>
import TermsComponent from '@/components/terms';

export default {
    name: 'TermsPage',
    components: {
        TermsComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>