<template>
  <div class="mypage">
    <div>
    </div>
    <h1 class="mypage__title">マイページ</h1>
    <div class="mypage__content">
      <div class="mypage__section">
        <h2 class="mypage__section-title">プロフィール</h2>
        <div class="mypage__profile">
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">ユーザID:</span>
            <span class="mypage__profile-value">{{ userId }}</span>
          </div>
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">名前:</span>
            <span class="mypage__profile-value">{{ user.userName }}</span>
          </div>
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">メールアドレス:</span>
            <span class="mypage__profile-value">{{ user.email }}</span>
          </div>
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">電話番号:</span>
            <span class="mypage__profile-value">{{ user.phoneNo }}</span>
          </div>
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">住所:</span>
            <span class="mypage__profile-value">{{ user.address }}</span>
          </div>
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">会員種別:</span>
            <span class="mypage__profile-value">{{ user.membershipType }}</span>
          </div>
          <div class="mypage__profile-item">
            <span class="mypage__profile-label">有効期限:</span>
            <span class="mypage__profile-value">{{ user.expiryDate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import cognito from '@/service/cognito';
export default {
  name: 'MyPage',
  data() {
    return {
      userId: undefined,
      user: {
        "userId": "",
        "userName": "",
        "address": "",
        "email": "",
        "phoneNo": "",
        "membershipType": "",
        "expiryDate": "",
      },
      isAuthenticated: false,
    };
  },
  created() {
    

    // 初回表示時に実行したい処理をここに記述します
    cognito.isAuthenticated()
      .then(session => {
        console.log(session);
        this.isAuthenticated = true;
        // セッションからユーザー名を取得
        this.userId = session.idToken.payload['cognito:username'];
        this.fetchData(); // 初期表示時にメソッドを呼び出す

      })
      .catch(session => {
        console.log(session);
        this.isAuthenticated = false;
      });


  },
  methods: {
    fetchData() {

      axios.get('https://1xel0yl9ye.execute-api.ap-northeast-1.amazonaws.com/v1/getUser?userId='+this.userId)
        .then(response => {
          // レスポンスデータを処理するコードを追加する
          this.user.userName = response.data.userName
          this.user.address = response.data.address
          this.user.email = response.data.email
          this.user.phoneNo = response.data.telNo
          this.user.membershipType = response.data.membershipType
          this.user.expiryDate = this.formatDate(response.data.expiryDate)
        })
        .catch(error => {
          // エラーハンドリングのコードを追加する
          console.log(error);
        });
    },
    formatDate(expiryDate) {
      const year = expiryDate.slice(0, 4);
      const month = expiryDate.slice(4, 6);
      const day = expiryDate.slice(6, 8);
      return `${year}年${month}月${day}日`;
    }
  }
};
</script>
  
<style scoped>
.mypage {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.mypage__title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.mypage__content {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
}

.mypage__section {
  margin-bottom: 30px;
}

.mypage__section-title {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.mypage__profile {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mypage__profile-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mypage__profile-label {
  font-weight: bold;
  color: #555;
  margin-right: 10px;
}

.mypage__profile-value {
  color: #333;
}
</style>
  