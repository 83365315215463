<template>
    <div>
        <LoginComponent />
    </div>
</template>
  
<script>
import LoginComponent from '@/components/certification/login';

export default {
    name: 'LoginPage',
    components: {
        LoginComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>