<template>
<h1>メールアドレスの認証が完了しました。</h1>
<h2>右上のログインボタンよりログインしてください</h2>
</template>
  
  <script>
  export default {
    name: 'CompletePage',
  }
  </script>
