<template>
  <div class="top-page">
    <img src="../assets/safg2.jpg" alt="Card Image" class="gazou">
    <h2 class="sub-title">VISION</h2>
    
    <hr class="under-bar">
    <h2 class="message">Art has great power. We are creating the SynchroArt.</h2>
    <p class="description">一般財団法人SynchroArt Foundationは、
          芸術文化の振興に関する活動を行い、優れた美術文化芸術家の育成を行うとともに、
          芸術文化の啓蒙及び促進により、人々の創造性や表現力を育み、
          さらに、他者への理解と尊重し合い多様性を受け入れることができる
          心豊かな社会の形成に寄与することを目的としています。</p>

    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      :interval="3000"
      hide-delimiters
    >
      <v-carousel-item
        v-for="item in mockData"
        :key="item.id"
        class="slide-item"
        @click="redirectToNews(item)"
      >
        <v-sheet
          :style="{ backgroundColor: 'transparent' }"
          height="100%"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-img
              :src="item.image"
              aspect-ratio="16/9" 
              max-width="100%"
              max-height="100%"
              class="mx-auto"
              
            ></v-img>
          
            <div class="slide-card-container">
              <v-card class="slide-card">
                <v-card-text>
                  <p class="date">{{ item.date }}</p>
                  <hr class="under-bar">
                  <p class="title">{{ item.title }}</p>
                  <p class="outline">{{ item.outline }}</p>
                </v-card-text>
              </v-card>
            </div>

          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;UPDATES&nbsp;&nbsp;&nbsp;/</h1>
    </div>  
    <div class="card-grid">
      <div class="card" v-for="item in mockData" :key="item.id" @click="redirectToNews(item)">
        <div class="image-container">
          <img :src="item.image" alt="Card Image" class="card-image">
        </div>
        <p class="update-title">
          {{ item.date }}<br>
          {{ item.title }}<br>
          -MORE
          </p>
      </div>
    </div>

  </div>
</template>



<script>
export default {
  name: 'HomeComponent',
  data() {
    return {

      mockData: [
        {
          id: 1,
          title: 'ナイトアートプロジェクト「WONDER AQUA」開催！',
          image: require('../assets/wonderaqua.jpg'),
          date: '2023/10/2',
          outline:  '佐賀県佐賀城にて、当財団理事の藤原KAZUさんが全プロデュースしているナイトアートプロジェクト「WONDER AQUA」が今月から来月まで開催されます🐬'
        },
        {
          id: 2,
          title: '「TOKYO LIGHTS 2023」開催まであと2日！',
          image: require('../assets/tokyo-lights.png'),
          date: '2023/9/8',
          outline:  '世界最大級のプロジェクションマッピング国際大会「TOKYO LIGHTS 2023」開催まであと２日！世界のトップクリエイターたちの映像作品を圧倒的スケールで体感できるイベントです。'
        },
        {
          id: 3,
          title: 'SynchoArt Foundationトークセッション開催！',
          image: require('../assets/tailSession_0810.jpg'),
          date: '2023/8/3',
          outline:  '8/10 19時〜 虎ノ門ヒルズで、SynchoArt Foundationトークセッションを行います。ぜひご参加ください。'
        },
        {
          id: 4,
          title: '「ART SHINSAIBASHI コンテンポラリーアートコレクション」に、SynchroArt作品が出展！',
          image: require('../assets/contemporary.png'),
          date: '2023/5/30',
          outline:  '6/15(木)-21(水) 10時-20時(最終日は17時迄)「ART SHINSAIBASHI コンテンポラリーアートコレクション」に、SynchroArt作品が出展されます'
        },
        {
          id: 5,
          title: 'safトークセッション＆ミートアップ開催！',
          image: require('../assets/saf_talkSession.jpg'),
          date: '2023/2/14',
          outline:  '2/16 19時〜、虎ノ門ヒルズCICTokyo ベンチャーカフェにて、safトークセッション＆ミートアップを開催します！入場無料ですので、皆さん是非ご来場ください。'
        },
        {
          id: 6,
          title: 'XANA GENESIS NFT フィジカルアート展@ Ginza Six 5F Saf Gallery',
          image: require('../assets/nft_physicalArt.jpg'),
          date: '2023/1/26',
          outline:  'XANA NFT 原画展を1月27日から1月29日にSaf Galleryに開催します！\n興味があります方はぜひお越しください！'
        },


      ], 
    }
  },
  methods: {
    redirectToNews(item) {
      this.$router.push({ name: 'News', params: { id: item.id } });
    }
  }  
}
</script>

<style scoped>
.top-page{
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.page-title{
  margin-top: 100px;
  margin-bottom: 50px;
}

.update-title {
  font-size: 14px;
  text-align: left;
}


/*スライダーのスタイル*/
.v-carousel:hover::before { /* ホバー時にオーバーレイを表示 */
  content: ""; /* 空のコンテンツを追加 */
  position: absolute; /* カード内で絶対的な位置を設定 */
  top: 0; /* 上端に配置 */
  left: 0; /* 左端に配置 */
  width: 100%; /* カードの幅に合わせる */
  height: 100%; /* カードの高さに合わせる */
  background-color: rgba(255, 255, 255, 0.7); /* 白い透明なオーバーレイ */
  z-index: 1; /* オーバーレイがカードの下に表示されるように設定 */
  transition: opacity 0.3s ease; /* オーバーレイの表示をアニメーション化 */
  pointer-events: none; /* オーバーレイをクリックできなくする */
}

.gazou{
  margin-top: 0px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.message{
  font-size: 22px;
}
.description{
  margin-bottom: 100px;
}

.slide-item {
  width: 100%;
  height: auto; 
}

.slide-item .v-img {
  width: 100%;
  height: 100%; 
}


.slide-card-container {
  position: absolute;
  bottom: 60px;
  right: 50px;
  width: 300px; /* 固定したい幅を設定 */
  height: 150px; /* 高さを自動調整 */
}

.slide-card {
  background-color: #333; 
  color: #fff; 
  height: 100%; 
  overflow: hidden; 
}

.slide-card .v-card-text {
  padding: 10px;
  max-height: 100%; 
}

.slide-card .date{
  font-size: 12px;
}
.slide-card .title{
  font-size: 16px;
}
.slide-card .outline{
  font-size: 14px;
  text-overflow: ellipsis; 
  overflow: hidden; 
}
.slide-card .under-bar {
  border: none; 
  background-color: white; 
  height: 1px; 
  width: 70px; 
  margin-top: 5px;
  margin-bottom: 10px;
}




/*カードのスタイル*/
.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列のグリッド */
  gap: 20px; 
  margin-bottom: 50px;
}

.card {
  border: none;
  padding: 20px;
  position: relative;
  height: 100%; /* カードの高さを100%に設定 */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; /* 親要素に対する高さ*/
}

.image-container img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  object-position: center;
}
.card .image-container {
  /* 画像コンテナ内の高さを設定 */
  height: 300px; /* この値を調整して適切な高さに変更してください */
}

.card:hover::before { 
  content: ""; 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background-color: rgba(255, 255, 255, 0.7); 
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease; 
  pointer-events: none; 
}

.card:hover::before {
  opacity: 1; /* ホバー時にオーバーレイを表示 */
}







@media (max-width: 767px) {
.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列のグリッド */
  gap: 20px; 
  margin-bottom: 30px;
}

.card {
  border: none;
  padding: 0px;
  position: relative;
  height: 80%; 
}

.gazou{
  max-width: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; /* 親要素に対する高さ*/
}

.image-container img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  object-position: center;
}
.card .image-container {
  width: 100%;
  height: 120px; 
}

.card:hover::before { 
  content: ""; 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 120%; 
  background-color: rgba(255, 255, 255, 0.7); 
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease; 
  pointer-events: none; 
}
.card:hover::before {
  opacity: 1; /* ホバー時にオーバーレイを表示 */
}



.slide-card-container {
  margin-top: 0px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: auto;
}

/* スライド画像の下にカードを配置 */
.slide-card {
  margin-top: 0px; 
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #333; /* 濃いグレー */
  text-align: left;
  width: 100%;
}

/* カード内のスタイル */
.slide-card-inner {
  background-color: transparent; 
  width: 100%; 
}

.slide-item .v-img{
  width:auto;
  height: auto;
}
}

</style>

