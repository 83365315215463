<template>
    <div>
        <ServiceComponent />
    </div>
</template>
  
<script>
import ServiceComponent from '@/components/service';

export default {
    name: 'ServicePage',
    components: {
        ServiceComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>