<template>
  <div class="article">
    <div class="page-title">
      <h1>/&nbsp;&nbsp;&nbsp;NEWS&nbsp;&nbsp;&nbsp;/</h1>
    </div>

    <p class="article-date">{{ selectedData.date }}</p>
    <div class="image-container">
      <img class="article-image" :src="selectedData.image" alt="Card Image">
    </div>
    <h1 class="article-title">{{ selectedData.title }}</h1>
    <p class="article-detail" v-html="formatOutlineAndLinks(selectedData.outline)"></p>
    
  </div>
</template>

<script>
export default {
  name: 'NewsComponent',
  data() {
    return {
      selectedData: {
        title: '',
        image: '',
        date: '',
        outline: ''
      },
    };
  },

  created() {
    // ページが作成された際にカードの内容を取得
    this.getSelectedCardData();
    console.log(this.selectedData);
  },
  methods: {
    // ページが作成された際にカードの内容を取得
    getSelectedCardData() {
      // ホームページからカードのデータを受け取るロジックを実装
      const cardId = parseInt(this.$route.params.id, 10);// ルートパラメータからカードのIDを取得
      console.log(cardId);
      // カードのIDに基づいてデータを設定
      if (cardId === 1) {
        this.selectedData = {
          title: '佐賀県佐賀城にて、ナイトアートプロジェクト「WONDER AQUA」開催！',
          image: require('../assets/wonderaqua.jpg'),
          date: '2023/10/2',
          outline: '佐賀県佐賀城にて、当財団理事の藤原KAZUさんが全プロデュースしているナイトアートプロジェクト「WONDER AQUA」が開催されます🐬水中の世界をテーマにした「WONDER AQUA」では、まるで海の中にいるような立体的な映像を体験することができます。プロジェクションマッピングやデジタルアートだけでなく、公園内のライトアップやキッチンカーも楽しむことができます。さらに、会期中は世界初となる参加型ホログラム体験も用意されています。\n\n\n開催場所　　：　佐賀城公園（佐賀城本丸歴史館周辺エリア)\n開催日　　　：　2023年10月13日(金)～11月26日(日)\n開催時間　　：　18:30～21:30\n公式サイト　：　https://saga-ken.com/'
        };
        console.log(this.selectedData.title, this.selectedData.image, this.selectedData.date, this.selectedData.outline);
      } else if (cardId === 2) {
        // 他のカードのデータを同様に設定
        this.selectedData = {
          title: '「TOKYO LIGHTS 2023」開催まであと2日！',
          image: require('../assets/tokyo-lights.png'),
          date: '2023/9/8',
          outline: '世界最大級のプロジェクションマッピング国際大会「TOKYO LIGHTS 2023」開催まであと２日！世界のトップクリエイターたちの映像作品を圧倒的スケールで体感できるイベントです。過去最多のエントリーから厳選された20組のプロジェクションマッピング作品の上映をお楽しみください！\n\n\n会場　　　：　明治神宮外苑 聖徳記念絵画館及び総合球技軟式球場\n開催日時　：　2023年9月8日(金)～10日(日)\n公式サイト：　https://tokyolights.jp/　\nチケットはこちらから↓\nhttps://peatix.com/group/12752261/events'
        };        
        console.log(this.title, this.image, this.date, this.outline);
      } else if (cardId === 3){
        this.selectedData = {
          title: 'SynchoArt Foundationトークセッション開催！',
          image: require('../assets/tailSession_0810.jpg'),
          date: '2023/8/3',
          outline: '8/10 19時〜 虎ノ門ヒルズで、SynchoArt Foundationトークセッションを行います。ぜひご参加ください。\n\n「アートの民主化について考える」イベント公式サイト↓\nhttps://venturecafetokyo.org/sessions/think-about-democratization-of-art/ \nスピーカー ： 坂本大地、服部亮',
        };             
        console.log(this.title, this.image, this.date, this.outline);
      } else if (cardId === 4){
        this.selectedData = {
          title: '「ART SHINSAIBASHI コンテンポラリーアートコレクション」に、SynchroArt作品が出展！',
          image: require('../assets/contemporary.png'),
          date: '2023/5/30',
          outline: '「ART SHINSAIBASHI コンテンポラリーアートコレクション」に、SynchroArt作品が出展されます。\n\n今回は心斎橋PARCOのスカイギャラリーの全スペースを頂き、特別企画として場をご用意いただきました。\nぜひ遊びにいらしてください!\n\n公式サイト　：　https://www.daimaru.co.jp/shinsaibashi/art_shinsaibashi/space14/',
        };             
        console.log(this.title, this.image, this.date, this.outline);
      } else if (cardId === 5){
        this.selectedData = {
          title: 'safトークセッション＆ミートアップ開催！',
          image: require('../assets/saf_talkSession.jpg'),
          date: '2023/2/14',
          outline: '2/16 19時〜、虎ノ門ヒルズCICTokyo ベンチャーカフェにて、safトークセッション＆ミートアップを開催します！\n入場無料ですので、皆さん是非ご来場ください。\n\n「知的財産権×DX〜web3の技術を用いた芸術分野の革新〜」\nhttps://venturecafetokyo.org/sessions/intellectual-property-rights-and-dx/',
        };             
        console.log(this.title, this.image, this.date, this.outline);
      } else if (cardId === 6){
        this.selectedData = {
          title: 'XANA GENESIS NFT フィジカルアート展@ Ginza Six 5F Saf Gallery',
          image: require('../assets/nft_physicalArt.jpg'),
          date: '2023/1/26',
          outline: 'XANA NFT 原画展を1月27日から1月29日にSaf Galleryに開催します！\n興味があります方はぜひお越しください！\n\n【開催概要】\n期間　：　1月27日(金)〜29日(日)\n時間　：　10:30〜20:30\n場所　:　GINZA SIX 5F Saf Gallery\n料金　：　入場無料',
        };            
        console.log(this.title, this.image, this.date, this.outline);
      }
      console.log(this.selectedData);
    },

    formatOutlineAndLinks(text) {
      // 改行とリンクのフォーマットを適用する
      const outlinedText = this.formatOutline(text);
      return this.formatLinks(outlinedText);
    },
    formatOutline(text) {
      return text.replace(/\n/g, '<br>'); // outline内の改行を<br>タグに変換
    },
    formatLinks(text) {
      // URLをリンクとしてフォーマット
      const linkPattern = /https?:\/\/[^\s]+/g;
      return text.replace(linkPattern, url => {
        return `<a href="${url}" target="_blank">${url}</a>`;
      });
    }
  },
}
</script>



<style scoped>
.page-title{
  margin-top: 150px;
  margin-bottom: 100px;
}
.article{
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}


.image-container{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.article-image{
  
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.article-title{
  margin-top: 30px;
  margin-bottom: 30px;
}

.article-detail{
  line-height: 36px;
  font-size: 18px;
  margin-bottom: 100px;
}




@media (max-width: 767px) {
  /* スマートフォン向けのスタイル調整 */

  .article-title {
    font-size: 20px; 
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .article-detail {
    line-height: 28px;
    font-size: 14px; 
  }
}
</style>