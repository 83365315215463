<template>
    <div>
        <PaymentResultComponent />
    </div>
</template>
  
<script>
import PaymentResultComponent from '@/components/payment/paymentSuccess';

export default {
    name: 'PaymentResultPage',
    components: {
        PaymentResultComponent
    },
    // ページコンポーネントのオプション
}
</script>
  
<style>
/* ページコンポーネントのスタイル */
</style>