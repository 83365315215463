<template>
  <v-card>
        <v-card-title class="headline">
          プライバシーポリシー
        </v-card-title>

        <v-card-text>
    <div class="policy">
      <div class="description">
        <p>
          一般財団法人全世界シンクロ・アート財団（以下「当財団」といいます。）は、お客様や関係者の個人情報（個人番号及び特定個人情報を含む）を適正に保護することは大変重要な責務であると自覚しております。当財団は、以下の方針に基づき個人情報の保護に努めてまいります。
        </p>
      </div>

      <div class="description">
        <p>1. 関係法令等の遵守について</p>
        <p>
          当財団は、「行政手続における特定の個人を識別するための番号の利用等に関する法律」（以下「番号法」という。）及び「個人情報の保護に関する法律」（以下「個人情報保護法」という。）並びにこれらに基づく政省令等を遵守します。
        </p>
      </div>

      <div class="description">
        <p>2. 個人情報の取得について</p>
        <p>
          当財団は、個人情報を取り扱う事務の目的を明確にし、適法かつ公正な手段によって、原則としてあらかじめ利用目的を通知又は公表したうえで、個人情報を取得します。個人情報を取得する場合は、原則としてご本人様から取得します。
        </p>
      </div>

      <div class="description">
        <p>3. 個人情報の利用について</p>
        <p>
          当財団は、取得等の際に示した利用目的の範囲内で、かつ業務の遂行上必要な限度内で、個人情報を利用します。個人情報の取扱いを第三者に委託する場合は、当該第三者に秘密を厳守するよう契約を締結し、その責任の所在を明確にし、個人情報の安全管理のために必要かつ適切な監督を行います。
        </p>
      </div>

      <div class="description">
        <p>4. 個人情報の第三者提供について</p>
        <p>
          当財団は、原則として以下に定める場合を除くほか、個人情報を第三者に提供しません。<br>
          ・お客様の同意がある場合<br>
          ・個人情報保護法及び番号法その他法令に定めのある場合
        </p>
      </div>

      <div class="description">
        <p>5. 個人情報の管理について</p>
        <p>
          当財団は、個人情報の正確性を保ち、法令に基づき安全に管理します。個人情報の漏洩、滅失又は毀損などを防ぐため、役職員等が使用するPCにはファイアーウォールを導入し、コンピュータウィルス等に対する適正かつ有効な情報セキュリティ対策を実施します。
        </p>
      </div>

      <div class="description">
        <p>6. 個人情報の開示、訂正、利用停止、消去について</p>
        <p>
          当財団は、お客様や関係者がご自身の個人情報について、開示・訂正・利用停止・消去を申し出られた場合は速やかに対応いたします。その手続、開示等の方法につきましては下記窓口にてご説明致します。<br>
          個人情報相談窓口：財団事務局総務課庶務係個人情報担当<br>
          電話: 03-6206-4614<br>
          開示を行う場合は、別途定める金額を申し受けます。
        </p>
      </div>

      <div class="description">
        <p>7. 保有個人情報に関する事項の公表について</p>
        <p>
          当財団は、保有個人情報（当財団職員が職務上作成し、又は取得した個人情報であって、当該職員が組織的に利用するものをいいます。）の利用目的、その変更その他法令及び財団の規程が定める事項を公表するときは、当財団のホームページ（https://synchroart.or.jp/）にて行います。
        </p>
      </div>

      <div class="description">
        <p>8. 組織・体制について</p>
        <p>
          当財団は、個人情報保護管理者及び特定個人情報等保護管理者に理事長を任命し、保有個人情報の安全管理を実施します。各事業所に、個人情報作業責任者及び特定個人情報等事務取扱責任者を設置し、保有個人情報を責任もって取扱います。職員全員に対し、個人情報の保護についての研修を実施し、日常業務における個人情報の適正な取扱いを徹底します。
        </p>
      </div>

      <div class="description">
        <p>9. 個人情報保護関連規程の制定・実施・改善</p>
        <p>
          当財団は、上記の方針を徹底するため、個人情報保護関連の規定を整備し、これを役職員等及び関係者に周知し、実施し、必要に応じて日々改善致します。
        </p>
      </div>

    </div>
        </v-card-text>

        <v-divider></v-divider>

      </v-card>
    
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy',
  };
  </script>
  
  <style scoped>
    .policy {
      font-size: 12px;
    }

    .description {
      margin-bottom: 20px;
    }

  .headline {
    font-size: 18px;
    
    margin-top: 100px;
    margin-bottom: 70px;
    text-align: center;
  }
  
  .grey {
    background-color: #f5f5f5;
  }
  
  .lighten-2 {
    padding: 10px;
    border-radius: 4px;
  }
  </style>